/* Css For MetroBank */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header/style-one/style-two
5. Main Slider/style-one/style-two
6. Intro Section
7. Welcome Section
9. Cta Section
8. Research Fields
10. Testimonial Section
11. Researches Section
12. Team Section
14. Video
15. Fact Counter
16. News Section
19. Clients Section
20. Main Footer
21. Footer Bottom
22. Research Style Two
23. Innovative Solution
24. Discover Section
25. Testimonial Section
26. Chooseus Section
27. News Style Two
28. Page Title
29. Research Page Section
30. Research Details
31. Professor Page Section
32. Professor Details
33. About Section
34. Error Section
35. Blog Page Section
36. Blog Details
37. Blog Sidebar
38. Contact Section
39. Google Map


**********************************************/

@import url('flaticon.css');
@import url('./animate.css');
@import url('./bootstrap.css');
@import url('./color.css');
@import url('./elpath.css');
@import url('./font-awesome-all.css');
@import url('./jquery.fancybox.min.css');
@import url('./nice-select.css');
/* @import url('./owl.css'); */
@import url('color/theme-color.css');
@import url('module-css/about.css');
@import url('module-css/apps.css');
@import url('module-css/banner.css');
@import url('module-css/blog-details.css');
@import url('module-css/calculator.css');
@import url('module-css/card.css');
@import url('module-css/career.css');
@import url('module-css/career-details.css');
@import url('module-css/contact.css');
@import url('module-css/error.css');
@import url('module-css/exchange.css');
@import url('module-css/faq.css');
@import url('module-css/feature.css');
@import url('module-css/funfact.css');
@import url('module-css/news.css');
@import url('module-css/page-title.css');
@import url('module-css/process.css');
@import url('module-css/requirements.css');
@import url('module-css/service.css');
@import url('module-css/sidebar.css');
@import url('module-css/subscribe.css');
@import url('module-css/team.css');
@import url('module-css/team-details.css');
@import url('module-css/testimonial.css');
@import url('module-css/video.css');

/*** 

====================================================================
  Reset
====================================================================

 ***/

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}

img {
  border-radius: 20px;
}

/*** 

====================================================================
  Global Settings
====================================================================

 ***/

:root {
  --theme-color: #0887fc;
  --secondary-color: #0a0f20;
  --pink-color: #ffc0cb;
  --violet-color: #7f00ff;
  --crimson-color: #dc143c;
  --orange-color: #ffa500;
  --text-color: #fff;
  --title-color: #141417;
  --gradient-color: linear-gradient(
    to top,
    lightgrey 0%,
    lightgrey 1%,
    #e0e0e0 26%,
    #efefef 48%,
    #d9d9d9 75%,
    #bcbcbc 100%
  );
}

body {
  font-size: 15px;
  color: var(--text-color);
  /* line-height: 28px; */
  background: #ffffff;
  font-family: 'Poppins' !important;
  font-weight: 400;
  font-style: normal;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  -webkit-font-smoothing: antialiased;
  -webkit-user-select: none; /* Prevent text selection */
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none; /* Disable long press menus */
  -webkit-tap-highlight-color: transparent;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
    padding: 0px 15px;
  }
}

.large-container {
  max-width: 1710px;
  padding: 0px 15px;
  margin: 0 auto;
}

.container-fluid {
  padding: 0px;
}

.auto-container {
  position: static;
  max-width: 1320px;
  padding: 0px 15px;
  margin: 0 auto;
}

.small-container {
  max-width: 680px;
  margin: 0 auto;
}

.boxed_wrapper {
  position: relative;
  margin: 0 auto;
  overflow: hidden !important;
  width: 100%;
  min-width: 300px;
}

a {
  text-decoration: none;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

a:hover {
  text-decoration: none;
  outline: none;
}

input,
button,
select,
textarea {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 18px;
  background: transparent;
}

::-webkit-input-placeholder {
  color: inherit;
}

::-moz-input-placeholder {
  color: inherit;
}

::-ms-input-placeholder {
  color: inherit;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

input {
  transition: all 500ms ease;
}

button:focus,
input:focus,
textarea:focus {
  outline: none;
  box-shadow: none;
  transition: all 500ms ease;
}

p {
  position: relative;
  font-family: 'Poppins';
  color: var(--text-color);
  font-weight: 400;
  margin: 0px;
  transition: all 500ms ease;
}

h1,
h2,
h3 {
  position: relative;
  font-family: 'Poppins-Bold';
  font-weight: bold;
  background: -webkit-linear-gradient(
      253deg,
      #848482 0%,
      #e0e0e0 24%,
      #e5e4e2 49%,
      #c0c0c0 73%,
      #848482 100%
    )
    0% 0% no-repeat padding-box;
  /* background: linear-gradient(to top, lightgrey 0%, lightgrey 1%, #e0e0e0 26%, #efefef 48%, #d9d9d9 75%, #bcbcbc 100%); */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0px;
  transition: all 500ms ease;
}

h4,
h5 {
  position: relative;
  font-family: 'Poppins-Bold';
  font-weight: bold;
  color: white;
  margin: 0px;
  transition: all 500ms ease;
}

h6 {
  position: relative;
  font-family: 'Poppins-Bold';
  font-weight: bold;
  /* background: linear-gradient(to top, #0887fc 0%, #00c6fb 100%); */
  color: '#fff';
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  margin: 0px;
  transition: all 500ms ease;
}
/* Preloader */

.handle-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
  background: #0e1d40;
}

.preloader-close {
  position: fixed;
  z-index: 99999999;
  font-size: 14px;
  background: #fff;
  color: red;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  right: 30px;
  top: 30px;
  font-weight: 400;
}

.handle-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}

.handle-preloader .animation-preloader .spinner {
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  height: 150px;
  margin: 0 auto 45px auto;
  width: 150px;
}

.handle-preloader .animation-preloader .txt-loading {
  text-align: center;
  user-select: none;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top: 0;
  position: absolute;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading {
  font-family: 'Poppins';
  font-weight: 500;
  letter-spacing: 15px;
  display: inline-block;
  position: relative;
  font-size: 60px;
  line-height: 60px;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.3);
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(9):before {
  animation-delay: 1.6s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(10):before {
  animation-delay: 1.8s;
}

.handle-preloader .loader-section {
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}

.preloader .loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before {
  color: #ffffff;
}

.handle-preloader .animation-preloader .spinner {
  border: 3px solid #ffffff;
  border-top-color: rgba(255, 255, 255, 0.5);
}

/* AnimaciÃ³n del preloader */
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .handle-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
}

@media screen and (max-width: 500px) {
  .handle-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }

  .handle-preloader .animation-preloader .txt-loading .letters-loading {
    font-size: 30px;
    letter-spacing: 10px;
  }
}

.centred {
  text-align: center;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

figure {
  margin: 0px;
}

img {
  display: inline-block;
  max-width: 100%;
  /* height: auto; */
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.7s;
  transition-property: all;
}

.row {
  --bs-gutter-x: 30px;
}

/** button **/

.theme-btn {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  font-size: 15px;
  line-height: 15px;
  font-weight: 700;
  border-radius: 25px;
  font-family: var(--manrope);
  color: #fff !important;
  text-align: center;
  padding: 20px 40px;
  z-index: 1;
  transition: all 500ms ease;
}

.theme-btn.btn-one {
  background: var(--theme-color);
}

.theme-btn.btn-two {
  background: var(--secondary-color);
}

.theme-btn.btn-three {
  background: #fff;
  color: var(--theme-color);
}

.theme-btn:before,
.theme-btn:after {
  content: '';
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--secondary-color);
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform-origin: center;
  transform: scale(0) rotate(0);
  z-index: -1;
}

.theme-btn.btn-two:before,
.theme-btn.btn-two:after {
  background: var(--theme-color);
}

.theme-btn:hover:before {
  border-radius: 0;
  transform: scale(1) rotate(-180deg);
}

.theme-btn:hover:after {
  border-radius: 0;
  transform: scale(1) rotate(180deg);
}

.parallax-bg {
  position: absolute;
  left: 0px;
  top: -30%;
  width: 100%;
  height: calc(100% + 30%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/** styled-pagination */

.pagination {
  position: relative;
  display: block;
}

.pagination li {
  position: relative;
  display: inline-block;
  margin: 0px 4px;
}

.pagination li a {
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  height: 60px;
  width: 60px;
  line-height: 62px;
  background: #ffffff;
  box-shadow: 0px 10px 40px 10px rgba(0, 0, 0, 0.07);
  text-align: center;
  color: var(--title-color);
  border-radius: 50%;
  z-index: 1;
  transition: all 500ms ease;
}

.pagination li a:hover,
.pagination li a.current {
  color: #fff;
  background: var(--theme-color);
  border-color: var(--theme-color);
}

.pagination li a i {
  font-size: 16px;
}

.mr-0 {
  margin: 0px !important;
}

/** scroll-to-top **/

.scroll-to-top {
  position: fixed;
  right: 10px;
  bottom: 60px;
  /* transform: rotate(90deg); */
  z-index: 99;
}

.scroll-to-top .visible {
  visibility: visible !important;
  opacity: 1 !important;
}

.scroll-to-top .scroll-top-inner {
  opacity: 0;
  visibility: hidden;
}

.scroll-to-top .scroll-top-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -o-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
}

.scroll-to-top .scroll-bar {
  width: 50px;
  height: 2px;
  margin-right: 10px;
  position: relative;
}

.scroll-to-top .scroll-bar:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f4244f;
  opacity: 0.3;
}

.scroll-to-top .scroll-bar .bar-inner {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: currentColor;
}

.scroll-to-top .scroll-bar-text {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: var(--theme-color);
  text-shadow: 0px 1px 0px #fff;
  transition: all 500ms ease;
}

.scroll-to-top .scroll-bar-text:hover {
  transform: scale(1.1);
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.sec-title {
  position: relative;
  display: block;
}

.sec-title h6 {
  display: block;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.sec-title h2 {
  display: block;
  font-size: 40px;
  line-height: 52px;
  font-weight: 800;
}

.sec-title.light .sub-title,
.sec-title.light h2 {
  color: #fff !important;
}

.sec-title p {
  margin-top: 17px;
}

.sec-title h2 span {
  color: var(--theme-color);
}

/*** 

====================================================================
                        Home-Page-One
====================================================================

***/

/** main-header **/

.main-header {
  position: relative;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 999;
  width: 100%;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.sticky-header {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.fixed-header .sticky-header {
  z-index: 999;
  opacity: 1;
  visibility: visible;
  -ms-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -op-animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -ms-animation-duration: 500ms;
  -moz-animation-duration: 500ms;
  -op-animation-duration: 500ms;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -ms-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -op-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -ms-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -op-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/** header-top **/

.header-top {
  position: relative;
  width: 100%;
  background: #212226;
  padding: 16px 0px;
}

.header-top .top-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 350px;
}

.header-top .links-list {
  position: relative;
  display: flex;
  align-items: center;
}

.header-top .links-list li {
  margin-right: 20px;
}

.header-top .links-list li:last-child {
  margin: 0px !important;
}

.header-top .links-list li a {
  position: relative;
  display: inline-block;
  color: #fff;
}

.header-top .links-list li a:hover {
  color: var(--theme-color);
}

.header-top .info-list {
  position: relative;
  display: flex;
  align-items: center;
}

.header-top .info-list li {
  position: relative;
  margin-right: 40px;
  padding-left: 32px;
  color: #fff;
}

.header-top .info-list li:last-child {
  margin: 0px !important;
}

.header-top .info-list li i {
  position: absolute;
  left: 0px;
  top: 5px;
  font-size: 18px;
  color: var(--theme-color);
}

.header-top .info-list li a {
  position: relative;
  display: inline-block;
  color: #fff;
}

.header-top .info-list li a:hover {
  color: var(--theme-color);
}

.main-header .outer-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-header .outer-box .logo-box {
  position: relative;
  padding: 61px 90px 61px 0px;
  margin-top: -60px;
  background: black !important;
  z-index: 1;
}

.main-header .outer-box .logo-box .shape {
  position: absolute;
  top: 0px;
  right: -24px;
  width: 24px;
  height: 100%;
  background: black !important;
  clip-path: polygon(100% 0%, 0% 50%, 100% 100%, 0 100%, 0 50%, 0 0);
}

.main-header .outer-box .logo-box:after {
  position: absolute;
  content: '';
  top: 0px;
  right: -32px;
  width: 32px;
  height: 100%;
  background: black !important;
  clip-path: polygon(100% 0%, 25% 50%, 100% 100%, 0 100%, 0 50%, 0 0);
  opacity: 0.15;
}

.main-header .outer-box .logo-box:before {
  position: absolute;
  content: '';
  background: black !important;
  width: 5000px;
  height: 100%;
  top: 0px;
  right: 0px;
  z-index: -1;
}

.main-header .menu-right-content {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header .menu-right-content .theme-btn {
  padding: 13px 30px;
}

.main-header .menu-area {
  position: relative;
  display: flex;
  align-items: center;
}

/** main-menu **/

.main-menu {
  float: left;
}

.main-menu .navbar-collapse {
  padding: 0px;
  display: block !important;
}

.main-menu .navigation {
  margin: 0px;
}

.main-menu .navigation > li {
  position: inherit;
  float: left;
  z-index: 2;
  margin: 0px 20px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation > li:last-child {
  margin-right: 0px !important;
}

.main-menu .navigation > li:first-child {
  margin-left: 0px !important;
}

.main-menu .navigation > li > a {
  position: relative;
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  padding-top: 26px;
  padding-bottom: 26px;
  font-weight: 600;
  font-family: var(--manrope);
  opacity: 1;
  color: #141417;
  z-index: 1;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu .navigation > li.dropdown > a {
  padding-right: 17px;
}

.main-menu .navigation > li.current > a,
.main-menu .navigation > li:hover > a {
}

.main-menu .navigation > li.dropdown > a:before {
  position: absolute;
  content: '\f107';
  font-family: 'Font Awesome 5 Pro';
  top: 28px;
  right: 0px;
  font-weight: 500;
  transition: all 500ms ease;
}

.main-menu .navigation > li > ul,
.main-menu .navigation > li > .megamenu {
  position: absolute;
  left: inherit;
  top: 100%;
  width: 230px;
  margin-top: 15px;
  z-index: 100;
  display: none;
  background: var(--secondary-color);
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li > ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation > li > ul > li {
  position: relative;
  width: 100%;
}

.main-menu .navigation > li > ul > li > a,
.main-menu .navigation > li > .megamenu li > a {
  position: relative;
  display: block;
  padding: 10px 25px;
  line-height: 24px;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  font-family: var(--manrope);
  color: #fff;
  text-align: left;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li > a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.main-menu .navigation > li > .megamenu li > a {
  padding-left: 0px;
}

.main-menu .navigation > li > .megamenu h4 {
  display: block;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.main-menu .navigation > li > ul > li > a:hover,
.main-menu .navigation > li > .megamenu li > a:hover {
  padding-left: 35px;
}

.main-menu .navigation > li > ul > li:last-child > a,
.main-menu .navigation > li > .megamenu li:last-child > a {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li.dropdown > a:after {
  font-family: 'Font Awesome 5 Pro';
  content: '\f105';
  position: absolute;
  right: 20px;
  top: 10px;
  display: block;
  line-height: 24px;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  z-index: 5;
}

.main-menu .navigation > li > ul > li > ul {
  position: absolute;
  left: 100%;
  top: 0%;
  margin-top: 15px;
  background: var(--secondary-color);
  width: 230px;
  z-index: 100;
  display: none;
  border-radius: 0px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li > ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation > li > ul > li > ul > li {
  position: relative;
  width: 100%;
}

.main-menu .navigation > li > ul > li > ul > li:last-child {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > ul > li > a {
  position: relative;
  display: block;
  padding: 10px 25px;
  line-height: 24px;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  font-family: var(--manrope);
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: left;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li > ul > li:last-child > a {
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > ul > li > a:hover {
  padding-left: 35px;
}

.main-menu .navigation > li > ul > li > ul > li.dropdown > a:after {
  font-family: 'Font Awesome 5 Pro';
  content: '\f105';
  position: absolute;
  right: 20px;
  top: 12px;
  display: block;
  line-height: 24px;
  font-size: 16px;
  font-weight: 900;
  z-index: 5;
}

.main-menu .navigation > li.dropdown:hover > ul,
.main-menu .navigation > li.dropdown:hover > .megamenu {
  visibility: visible;
  opacity: 1;
  margin-top: 0px;
  top: 100%;
}

.main-menu .navigation li > ul > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 0%;
  margin-top: 0px;
}

.main-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: -32px;
  top: 66px;
  width: 34px;
  height: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  color: #3b3b3b;
  cursor: pointer;
  display: none;
  z-index: 5;
  transition: all 500ms ease;
}

.main-menu .navigation li.current.dropdown .dropdown-btn,
.main-menu .navigation li:hover .dropdown-btn {
}

.main-menu .navigation li.dropdown ul li.dropdown .dropdown-btn {
  display: none;
}

.menu-area .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  background: var(--secondary-color);
  display: none;
}

.mobile-menu .nav-logo img {
  max-width: 160px;
}

.menu-area .mobile-nav-toggler .icon-bar {
  position: relative;
  height: 2px;
  width: 30px;
  display: block;
  margin-bottom: 5px;
  background-color: #fff;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.menu-area .mobile-nav-toggler .icon-bar:last-child {
  margin-bottom: 0px;
}

/** megamenu-style **/

.main-menu .navigation > li.dropdown > .megamenu {
  position: absolute;
  width: 100%;
  padding: 30px 50px;
  left: 0px;
}

.main-menu .navigation li.dropdown .megamenu li h4 {
  margin-bottom: 10px;
}

.sticky-header .main-menu .navigation > li > a {
  padding-top: 27px;
  padding-bottom: 27px;
}

.sticky-header .main-menu .navigation > li.dropdown > a:before {
  top: 27px;
}

.main-header .sticky-header .outer-box .logo-box {
  margin-top: 0px;
  padding-top: 21px;
  padding-bottom: 21px;
}

/** mobile-menu **/

.nav-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  color: #3786ff;
  display: none;
}

.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
  transition: all 900ms ease;
}

.mobile-menu .navbar-collapse {
  display: block !important;
}

.mobile-menu .nav-logo {
  position: relative;
  padding: 50px 25px;
  text-align: left;
  padding-bottom: 100px;
}

.mobile-menu-visible {
  overflow: hidden;
}

.mobile-menu-visible .mobile-menu {
  opacity: 1;
  visibility: visible;
}

.mobile-menu .menu-backdrop {
  position: fixed;
  left: 0%;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 900ms ease;
  background-color: #000;
}

.mobile-menu-visible .mobile-menu .menu-backdrop {
  opacity: 0.7;
  visibility: visible;
  right: 100%;
  -webkit-transition: all 0.8s ease-out 0s;
  -o-transition: all 0.8s ease-out 0s;
}

.mobile-menu .menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: #141417;
  padding: 0px 0px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  transition: all 900ms ease !important;
}

.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.mobile-menu .close-btn {
  position: absolute;
  right: 25px;
  top: 10px;
  line-height: 30px;
  width: 24px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -ms-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
}

.mobile-menu-visible .mobile-menu .close-btn {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.mobile-menu .close-btn:hover {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
}

.mobile-menu .navigation li {
  position: relative;
  display: block;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu .navigation:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu .navigation li > ul > li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu .navigation li > a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-menu .navigation li ul li > a {
  font-size: 16px;
  margin-left: 20px;
  text-transform: capitalize;
}

.mobile-menu .navigation li > a:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  border-left: 5px solid #fff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-menu .navigation li.current > a:before {
  height: 100%;
}

.mobile-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 6px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 5;
}

.mobile-menu .navigation li.dropdown .dropdown-btn.open {
  color: #ffffff;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul,
.mobile-menu .navigation > li.dropdown > .megamenu {
  display: none;
}

.mobile-menu .social-links {
  position: relative;
  padding: 0px 25px;
}

.mobile-menu .social-links li {
  position: relative;
  display: inline-block;
  margin: 0px 10px 10px;
}

.mobile-menu .social-links li a {
  position: relative;
  line-height: 32px;
  font-size: 16px;
  color: #ffffff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-menu .social-links li a:hover {
}

div#mCSB_1_container {
  top: 0px !important;
}

.mobile-menu .contact-info {
  position: relative;
  padding: 120px 30px 20px 30px;
}

.mobile-menu .contact-info h4 {
  position: relative;
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 20px;
}

.mobile-menu .contact-info ul li {
  position: relative;
  display: block;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 3px;
}

.mobile-menu .contact-info ul li a {
  color: rgba(255, 255, 255, 0.8);
}

.mobile-menu .contact-info ul li a:hover {
}

.mobile-menu .contact-info ul li:last-child {
  margin-bottom: 0px;
}

.main-header .outer-box {
  position: relative;
}

.owl-dots-none .owl-dots,
.owl-nav-none .owl-nav {
  display: none !important;
}

.owl-nav button {
  background: transparent;
}

.float-bob-y {
  animation-name: float-bob-y;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob-y;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob-y;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob-y;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob-y;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

.float-bob-x {
  animation-name: float-bob-x;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob-x;
  -webkit-animation-duration: 15s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob-x;
  -moz-animation-duration: 15s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob-x;
  -ms-animation-duration: 15s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob-x;
  -o-animation-duration: 15s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

/** rtl-switcher **/

.demo-rtl {
  position: fixed;
  top: 390px;
  left: 10px;
  z-index: 9999;
}

button.rtl {
  background: var(--theme-color);
  display: block;
  text-indent: inherit;
  font-size: 12px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: 700;
  margin: 0px;
  color: #fff !important;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: all 500ms ease;
}

.demo-ltr {
  position: fixed;
  top: 390px;
  left: auto;
  right: 10px;
  z-index: 9999;
}

button.ltr {
  background: var(--theme-color);
  display: block;
  text-indent: inherit;
  font-size: 12px;
  font-weight: 700;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0px;
  color: #fff !important;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 1);
  transition: all 500ms ease;
}

.boxed_wrapper.ltr .demo-rtl {
  display: block;
}

.boxed_wrapper.ltr .demo-ltr {
  display: none;
}

.boxed_wrapper.rtl .demo-rtl {
  display: none;
}

.boxed_wrapper.rtl .demo-ltr {
  display: block;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

/** main-footer **/

.main-footer {
  position: relative;
  background: #04060c;
}

.main-footer .widget-section {
  position: relative;
  padding: 110px 0px 113px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.main-footer .footer-logo {
  margin-bottom: 21px;
}

.main-footer p,
.main-footer a {
  color: #fff;
}

.main-footer a:hover {
  color: var(--theme-color);
}

.main-footer .logo-widget p {
  margin-bottom: 40px;
}

.main-footer .social-links {
  position: relative;
  display: flex;
  align-items: center;
}

.main-footer .social-links li {
  margin-right: 20px;
}

.main-footer .social-links li:last-child {
  margin: 0px !important;
}

.main-footer .social-links li a {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  color: #fff !important;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
  border-radius: 50%;
}

.main-footer .social-links li a:hover {
}

.main-footer .widget-title {
  position: relative;
  display: block;
  margin-bottom: 26px;
}

.main-footer .widget-title h4 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 800;
  color: #fff;
}

.main-footer .links-widget .links-list li {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 10px;
}

.main-footer .links-widget .links-list li:last-child {
  margin-bottom: 0px;
}

.main-footer .links-widget .links-list li a {
  position: relative;
  display: inline-block;
  padding-left: 15px;
}

.main-footer .links-widget .links-list li a:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  left: 0px;
  top: 11px;
  transition: all 500ms ease;
}

.main-footer .links-widget .links-list li a:hover:before {
  background: var(--theme-color);
}

.main-footer .contact-widget .form-inner .form-group {
  position: relative;
  margin-bottom: 20px;
}

.main-footer .contact-widget .form-inner .form-group .select-box {
  min-height: 55px;
  position: relative;
  display: block;
  width: 100%;
  height: 55px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  padding: 0px 20px;
  line-height: 55px;
  background: transparent;
  border-radius: 0px;
  transition: all 500ms ease;
}

.main-footer .contact-widget .form-inner .form-group .select-box .wide {
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
  background: var(--secondary-color);
  cursor: pointer;
}

.main-footer .contact-widget .form-inner .form-group:last-child {
  margin-bottom: 0px;
  padding-top: 10px;
}

.main-footer .contact-widget .form-inner .form-group .nice-select,
.main-footer .contact-widget .form-inner .form-group input[type='text'] {
  position: relative;
  display: block;
  width: 100%;
  height: 55px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  padding: 0px 20px;
  line-height: 55px;
  background: transparent;
  border-radius: 0px;
  transition: all 500ms ease;
}

.main-footer .contact-widget .form-inner .form-group .nice-select .option {
  color: #222;
}

.nice-select:before {
  position: absolute;
  content: '\e92e';
  font-family: 'icomoon';
  font-size: 12px;
  top: 0px;
  right: 20px;
  color: rgba(255, 255, 255, 0.5);
}

.main-footer .contact-widget .form-inner .form-group .theme-btn:before,
.main-footer .contact-widget .form-inner .form-group .theme-btn:after {
  background: #fff !important;
}

.main-footer .contact-widget .form-inner .form-group .theme-btn:hover {
  color: var(--title-color) !important;
}

.main-footer .pattern-layer .pattern-1 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 193px;
  height: 540px;
  background-repeat: no-repeat;
}

.main-footer .pattern-layer .pattern-2 {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 209px;
  height: 550px;
  background-repeat: no-repeat;
}

.footer-bottom {
  padding: 30px 0px;
}

.footer-bottom p a {
  color: var(--theme-color);
}

.footer-bottom p a:hover {
  text-decoration: underline;
}

/** header-style-two **/

.header-style-two {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
}

.header-style-two.main-header .outer-box .logo-box {
  padding-left: 35px;
  padding-right: 35px;
}

.header-style-two .logo-box:before,
.header-style-two .logo-box:after {
  display: none;
}

.header-style-two .header-lower {
  background: #fff;
  padding-right: 40px;
}

.header-style-two .header-top {
  padding-right: 40px;
}

.nav-style-one .owl-nav button {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 78px;
  background: #fff;
  text-align: center;
  font-size: 24px;
  color: var(--title-color);
  box-shadow: 0px 5px 40px 20px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  transition: all 500ms ease;
}

.nav-style-one .owl-nav button:hover {
  color: #fff;
  background: var(--theme-color);
}

.list-style-one {
  position: relative;
  display: block;
}

.list-style-one li {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  font-weight: 500;
  padding-left: 30px;
  margin-bottom: 19px;
}

.list-style-one li:last-child {
  margin-bottom: 0px;
}

.list-style-one li:before {
  position: absolute;
  content: '\e91d';
  font-family: 'icomoon';
  font-size: 18px;
  left: 0px;
  top: 0px;
  font-weight: 400;
  color: var(--theme-color);
}

.pt_115 {
  padding-top: 115px;
}

.tabs-box .tab {
  position: relative;
  display: none;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.tabs-box .tab.active-tab {
  display: block;
}

.tabs-box .tab {
  transform: scale(0.9, 0.9) translateY(0px);
}

.tabs-box .tab.active-tab {
  transform: scale(1) translateY(0px);
}

/** header-style-three **/

.header-style-three {
  position: relative;
  background: #000;
}

.header-style-three .header-top .top-inner {
  padding-left: 40px;
  padding-right: 40px;
}

.main-header.header-style-three .outer-box .logo-box {
  background: transparent !important;
  margin-top: 0px;
  padding: 31px 0px;
}

.header-style-three .logo-box:before {
  display: none;
}

.header-style-three .outer-box .logo-box:after {
  display: none;
}

.main-header .menu-right-content .theme-btn.btn-two {
  background: transparent;
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color) !important;
  padding: 12px 30px;
}

.main-header .menu-right-content .theme-btn.btn-two:hover {
  color: #000 !important;
}

.accordion-box .block .acc-content {
  display: none;
}

.accordion-box .block .acc-content.current {
  display: block;
}

/* Responsive Css */

@media only screen and (max-width: 1749px) {
  .header-lower {
    background: #fff;
  }
}

@media only screen and (max-width: 1499px) {
  .header-lower {
    background: #fff;
  }
}

@media only screen and (max-width: 1399px) {
  .header-lower {
    background: #fff;
  }
}

@media only screen and (max-width: 1299px) {
  .header-lower {
    background: #fff;
  }
}

@media only screen and (max-width: 1200px) {
  .main-menu,
  .sticky-header,
  .main-header.style-one .outer-container:before {
    display: none !important;
  }

  .header-lower {
    background: #fff;
  }

  .menu-area .mobile-nav-toggler {
    display: block;
    padding: 10px;
  }

  .megamenu ul li:first-child {
    display: none;
  }

  .main-header .outer-box .logo-box {
    margin-top: 0px;
    padding: 31px 30px !important;
  }

  .main-header .outer-box .logo-box:before,
  .main-header .outer-box .logo-box:after,
  .main-header .outer-box .logo-box .shape {
    display: none;
  }

  .header-top .top-inner {
    padding-left: 0px;
  }

  .rtl .header-top .top-inner {
    padding-right: 0px;
  }

  .header-style-two .header-top {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .main-menu .navigation > li > ul,
  .main-menu .navigation > li > ul > li > ul,
  .main-menu .navigation > li > .megamenu {
    display: block !important;
    visibility: hidden;
    opacity: 0;
  }

  .header-lower {
    background: #fff;
  }
}

@media only screen and (max-width: 991px) {
  .main-footer .footer-widget {
    margin: 0px 0px 30px 0px !important;
  }

  .header-lower {
    background: #fff;
  }

  .main-footer .widget-section {
    padding-bottom: 83px;
  }

  .header-style-two .header-top {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .header-top .top-inner {
    display: block;
    text-align: center;
  }

  .header-top .info-list {
    display: none;
  }

  .header-top .links-list,
  .header-top .info-list {
    justify-content: right;
  }

  .main-header .menu-right-content {
    display: none;
  }

  .sec-title h2 {
    font-size: 32px;
    line-height: 40px;
  }

  .main-footer .pattern-layer {
    display: none;
  }

  .main-footer .widget-section {
    padding: 80px 0px 50px 0px;
  }
}

@media only screen and (max-width: 599px) {
  .header-top .info-list {
    display: none;
  }
}

@media only screen and (max-width: 499px) {
  .mobile-menu {
    width: 100%;
  }

  .header-top .info-list {
    display: none;
  }

  .header-top .info-list li {
    display: inline-block;
    margin: 0px 5px !important;
  }

  .main-header .outer-box .logo-box {
    padding: 15px !important;
  }

  .header-style-two .header-top {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .header-style-two .header-lower {
    padding-right: 15px;
  }

  .rtl .header-style-two .header-lower {
    padding-left: 15px;
  }

  .header-style-two.main-header .outer-box .logo-box .logo {
    max-width: 150px;
  }

  .header-top .links-list li {
    margin-right: 20px;
  }

  .rtl .header-top .links-list li {
    margin-left: 20px;
  }

  .header-style-three .header-top .top-inner {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pagination li a {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}

.link {
  font-size: 0.9rem;
  font-weight: 600;
  color: white;
  cursor: pointer;
}

.textMuted {
  color: #959595;
  font-weight: 500;
}

.linearBackground {
  background: transparent
    linear-gradient(253deg, #848482 0%, #c0c0c0 24%, #e5e4e2 49%, #c0c0c0 73%, #848482 100%) 0% 0%
    no-repeat padding-box;
}

.cardCss {
  width: 320px;
  height: 200px;
  position: relative;
}

.cardCss1 {
  width: 320px;
  height: 200px;
  /* UI Properties */
  border: 3px solid #1098db;
  border-radius: 7px;
  position: relative;
}

.cardItem {
  top: 477px;
  left: 245px;
  height: 55px;
  background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.textLinearBackground {
  background: -webkit-linear-gradient(
      /* 253deg,
    #848482 0%,
    #c0c0c0 24%,
    #e5e4e2 49%,
    #c0c0c0 73%,
    #848482 100% */
        253deg,
      #7a7a79 0%,
      #bcbcbc 20%,
      #e6e6e6 50%,
      #bcbcbc 80%,
      #7a7a79 100%
    )
    0% 0% no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.textCenterLinearBackground {
  background: -webkit-linear-gradient(
      253deg,
      #848482 0%,
      #c0c0c0 24%,
      #e5e4e2 49%,
      #c0c0c0 73%,
      #848482 100%
    )
    0% 0% no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.account-total-count {
  background: transparent
    linear-gradient(253deg, #848482 0%, #c0c0c0 24%, #e5e4e2 49%, #c0c0c0 73%, #848482 100%) 0% 0%
    no-repeat padding-box;
  min-height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 1rem;
}

.quick-action-box {
  background: black;
  min-height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
}

input:focus,
textarea:focus,
select:focus {
  box-shadow: none !important;
}

.profile-details-box {
  border: 1px solid #565656;
}

.highlight-text {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.inputLabel {
  font-weight: 800;
  padding-bottom: 3px;
  background: -webkit-linear-gradient(
      253deg,
      #848482 0%,
      #c0c0c0 24%,
      #e5e4e2 49%,
      #c0c0c0 73%,
      #848482 100%
    )
    0% 0% no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.badgeText {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 5px;
}
.link-style {
  text-decoration: none;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.card-image-shadow {
  border-radius: 20px;
  box-shadow: rgba(128, 128, 128, 1) 0px 0px 2px 3px;
}
.grow img {
  transition: 1s ease;
}

.grow img:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 1s ease;
}
.programfee-table {
  background: black !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.highlightColor {
  color: #1098db;
}

.letterSpacing-1 {
  letter-spacing: 1.1px;
  /* line-height: 25px; */
}

.prevent-select {
  -webkit-user-select: none;
  /* / Safari / */
  -ms-user-select: none;
  /* / IE 10 and IE 11 / */
  user-select: none;
  /* / Standard syntax / */
}
.circle-border {
  width: 120px; /* Set the width and height to include border */
  height: 120px;
  border: 1px solid #ccc; /* Grey border */
  background-color: #1f1f1f;
  border-radius: 50%; /* Make it a circle */
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-img {
  width: 80px; /* Adjust image size as needed */
}

.apexcharts-legend-text {
  background: -webkit-linear-gradient(
      253deg,
      #848482 0%,
      #c0c0c0 24%,
      #e5e4e2 49%,
      #c0c0c0 73%,
      #848482 100%
    )
    0% 0% no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.apexcharts-legend-marker {
  top: 2.1px !important;
  left: -4px !important;
}
.row-spaces {
  --bs-gutter-x: 0.7rem; /* Horizontal spacing */
  --bs-gutter-y: 0.7rem; /* Vertical spacing */
}


.mobile-navbar{
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 80px;
  background: black;
  display: flex;
  align-items: center;
  /* border-bottom: 2px solid gray; */
}